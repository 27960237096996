import React from 'react';
import { Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslate } from 'react-admin';
import { time } from '~/utils';
import { useReferenceEntity } from '~/hooks';
import { getName } from '~/utils/identities';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
      '& > div': {
        width: 50,
        height: 50,
        '& svg': {
          fontSize: '1.7rem',
        },
      },
    },
    headline: {
      marginTop: '8px',
    },
    subtitle: {
      lineHeight: '20px',
      paddingBottom: '7px',
      paddingLeft: '1px',
      color: colors.blueGrey[500],
    },
  };
});

const DefaultSubtitle = (props) => {
  const { record, resource, translate } = props;

  const parts = [];
  if (record.id) {
    parts.push(`${translate(`resources.${resource}.name`, 1)} #${record.id}`);
  }
  const created = record.created || record.createdAt;
  if (created) {
    const m = time(created);
    parts.push(`created at ${m.format('l')} ${m.format('LTS')}`);
  }
  const updated = record.updated || record.updatedAt || record.lastUpdated || record.lastUpdatedAt;
  if (updated) {
    const m = time(updated);
    parts.push(`last modified at ${m.format('l')} ${m.format('LTS')}`);
  }
  const lastUpdatedBy =
    typeof record.lastUpdatedBy === 'string'
      ? {
          id: record.lastUpdatedBy,
          type: 'DASHBOARD_IDENTITY',
        }
      : {
          id: '',
          type: 'SYSTEM',
        };

  const { data } = useReferenceEntity(lastUpdatedBy, true);
  const originatorName = getName?.[lastUpdatedBy.type]?.(data ?? {}) ?? lastUpdatedBy.id;

  const final = [parts.join(', ')];
  if (lastUpdatedBy.id) {
    final.push(`by ${originatorName}`);
  }

  return <>{final.join(' ')}</>;
};

const DefaultSubtitleFn = () => '';

const ShowTitle = ({
  record,
  resource,
  headline,
  subtitle = DefaultSubtitleFn,
  translate,
  icon,
  iconFn,
}) => {
  const classes = useStyles();

  return record ? (
    <div className={classes.root}>
      {icon && <div className={classes.avatarWrapper}>{icon}</div>}
      {iconFn && <div className={classes.avatarWrapper}>{iconFn(record, resource)}</div>}
      <div>
        {headline ? (
          <Typography variant='h2' className={classes.headline}>
            {headline(record, resource, translate)}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography variant='subtitle1' className={classes.subtitle}>
            {subtitle !== DefaultSubtitleFn ? (
              <>{subtitle(record, resource, translate)}</>
            ) : (
              <DefaultSubtitle record={record} resource={resource} translate={translate} />
            )}
          </Typography>
        ) : null}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default withTranslate(ShowTitle);
